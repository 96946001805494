import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useWindowScroll } from "react-use";
import kebabCase from "lodash.kebabcase";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useMenuData } from "../hooks/useMenuData";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 3000;
`;

export const Header = ({ location, pageTitle, pageType, isHeaderVisible }) => {
  // Menu Data
  const data = useMenuData();

  // Search
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 900px)");

  const links = data.prismicSettings.data.menu
    .filter((page) => page.menu1.document !== null)
    .filter((page) => page.menu1.document.data !== undefined)
    .map((page, index) => {
      if (page.menu1.document.url === `/`) {
        return (
          <li
            key={`single_link_${page.menu1.document.id}_${index}`}
            id={`link-${kebabCase(page.menu1.document.data.title.text)}`}
            className="page-link"
          >
            <Link
              to={page.menu1.document.url}
              activeClassName="current"
              className={location.includes(`/exhibition/`) ? `current` : ``}
            >
              {page.menu1.document.data.title.text}
            </Link>
          </li>
        );
      } else {
        return (
          <li
            key={`single_link_${page.menu1.document.id}_${index}`}
            id={`link-${kebabCase(page.menu1.document.data.title.text)}`}
            className="page-link"
          >
            <Link
              to={page.menu1.document.url}
              activeClassName="current"
              partiallyActive
            >
              {page.menu1.document.data.title.text}
            </Link>
          </li>
        );
      }
    });

  return (
    <HeaderWrapper>
      {isDesktop ? (
        <DesktopMenu
          links={links}
          location={location}
          pageTitle={pageTitle}
          pageType={pageType}
          isSearchVisible={isSearchVisible}
          setIsSearchVisible={setIsSearchVisible}
          isHeaderVisible={isHeaderVisible}
        />
      ) : (
        <MobileMenu
          links={links}
          location={location}
          pageTitle={pageTitle}
          pageType={pageType}
          isSearchVisible={isSearchVisible}
          setIsSearchVisible={setIsSearchVisible}
          isHeaderVisible={isHeaderVisible}
        />
      )}
    </HeaderWrapper>
  );
};
