import React, { useEffect, useState } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import kebabCase from "lodash.kebabcase";
import smoothscroll from "smoothscroll-polyfill";
import "intersection-observer";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Styles
import "../styles/normalize.css";
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Container = styled.div`
  position: relative;

  min-height: calc(${(props) => props.height}px - 200px - 30px);

  & main {
    min-height: calc(${(props) => props.height}px - 200px - 111px);
  }
`;

const Layout = ({ children }) => {
  const height = use100vh();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <Location>
        {({ location }) => {
          return (
            <Container className={kebabCase(location.pathname)} height={height}>
              <DefaultSEO />
              <GlobalStyles />

              <App
                location={location.pathname}
                children={children}
                height={height}
              />
            </Container>
          );
        }}
      </Location>
    </PrismicProvider>
  );
};

export default Layout;
