import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// hooks
import useScrollHeaderToggle from "../hooks/useScrollHeaderToggle"; // Import your custom hook

// Icons
import { Logo } from "../icons/logo";

// Search
import SearchIndex from "../search/search-index";

const Navigation = styled.nav`
  background-color: transparent;
  transition: 300ms background-color ease;

  padding: 0 15px;

  &.menu-open {
    background-color: #fff;
  }

  & > a.logo {
    display: block;
    width: 100%;

    line-height: 0;

    background-color: #fff;

    padding: 15px 15px 10px 15px;

    position: relative;
    z-index: 100;

    text-align: center;

    & svg {
      width: 230px;
    }

    @media (max-width: 400px) {
      & svg {
        width: 180px;
      }
    }
  }

  & ol {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    background: white;
    padding: 15px;
    width: auto;
    transition: 1000ms transform ease;

    &.visible {
      transform: translateY(0);
    }

    &.hidden {
      transform: translateY(-200px);
    }

    /* width: 100%; */
    justify-content: center;

    & li {
      line-height: 28px;
      padding: 0 10px;

      & button {
        line-height: 32px !important;
      }

      &::after {
        content: ",";
        /* padding: 0 10px 0 0; */
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      & a {
        &.current {
          font-family: "Sabon LT W04 Italic", "Times", serif;
        }
      }

      &.search-icon {
        & button {
          font-size: 18px;
          line-height: 26px;

          & svg {
            width: 15px;
            height: auto;
          }
        }
      }
    }
  }

  &.search-is-open {
    & ol {
      & .page-link {
        display: none;
      }

      & .search-icon {
        display: grid;
        grid-template-columns: auto 20px;

        width: 100%;

        & svg {
          width: 15px;
          height: auto;
        }
      }
    }
  }
`;

export const MobileMenu = ({
  links,
  location,
  isSearchVisible,
  setIsSearchVisible,
}) => {
  const isVisible = useScrollHeaderToggle(100);

  return (
    <Navigation
      className={`display-flex space-between menu-open ${
        isSearchVisible ? `search-is-open` : `search-is-closed`
      }`}
    >
      <Link to={"/"} className="logo">
        <Logo />
      </Link>

      <ol className={`display-flex ${isVisible ? `visible` : `hidden`}`}>
        {links}
        <li className={`search-icon ${isSearchVisible ? `is-open` : ``}`}>
          <SearchIndex
            isSearchVisible={isSearchVisible}
            setIsSearchVisible={setIsSearchVisible}
            location={location}
            isDesktop={false}
          />
        </li>
      </ol>
    </Navigation>
  );
};
