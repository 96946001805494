import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { AnimatePresence } from "framer-motion";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Config
const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      artist: React.lazy(() => import("./src/pages/{PrismicArtist.url}")),
      exhibition: React.lazy(() =>
        import("./src/pages/{PrismicExhibition.url}")
      ),
      edition: React.lazy(() => import("./src/pages/{PrismicEdition.url}")),
      editions: React.lazy(() => import("./src/pages/editions")),
      exhibitions: React.lazy(() => import("./src/pages/exhibitions")),
      artists: React.lazy(() => import("./src/pages/artists")),
      home: React.lazy(() => import("./src/pages/index")),
      info: React.lazy(() => import("./src/pages/about")),
      fairs: React.lazy(() => import("./src/pages/fairs")),
      news: React.lazy(() => import("./src/pages/news")),
      page: React.lazy(() => import("./src/pages/{PrismicPage.url}")),
    },
  },
];

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </PrismicPreviewProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 1);
  }

  // if we used the browser's forwards or back button
  else {
    window.setTimeout(() => window.scrollTo(0, 0), 1);
  }

  return false;
};
