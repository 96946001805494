exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "home": {
      return `/`;
    }

    case "artist": {
      return `/artists/${doc.uid}/`;
    }

    case "exhibition": {
      return `/exhibitions/${doc.uid}/`;
    }

    case "edition": {
      return `/editions/${doc.uid}/`;
    }

    case "fairs": {
      return `/${doc.uid}/`;
    }

    case "page": {
      return `/${doc.uid}/`;
    }

    default: {
      return `/${doc.uid}/`;
    }
  }
};
