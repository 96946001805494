import React from "react";

// Components
import { Search } from "./search";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

// Icons
import { SearchIcon } from "../icons/search-icon";

const SearchIndex = ({
  isSearchVisible,
  setIsSearchVisible,
  underlineColor,
  hoverColor,
  fill,
  isDesktop,
}) => {
  const data = UseSearchData();

  if (isSearchVisible && data !== null) {
    return (
      <Search
        index={data.index}
        store={data.store}
        isSearchVisible={isSearchVisible}
        setIsSearchVisible={setIsSearchVisible}
        underlineColor={underlineColor}
        hoverColor={hoverColor}
        fill={fill}
        isDesktop={isDesktop}
      />
    );
  } else {
    return (
      <button
        type="button"
        onClick={() => setIsSearchVisible(true)}
        aria-label="Open search"
      >
        <SearchIcon fill={`#000`} />
      </button>
    );
  }
};

export default SearchIndex;
