import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Icons
import { Logo } from "../icons/logo";

// Search
import SearchIndex from "../search/search-index";

const Navigation = styled.nav`
  padding: 33px 30px 20px 30px;
  height: 30px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 24%,
    rgba(255, 255, 255, 0) 100%
  );

  & .logo {
    & svg {
      width: 360px;
      height: auto;

      @media (max-width: 1320px) {
        width: 270px;
      }

      @media (max-width: 1100px) {
        width: 230px;
      }
    }
  }

  & a {
    font-size: 24px;

    @media (max-width: 1300px) {
      font-size: 20px;
    }

    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  & ol {
    position: fixed;
    top: 42px;
    right: 30px;

    transition: 500ms all ease-in-out;

    & li {
      display: block;
      margin: 0 40px 0 0;

      &:last-of-type {
        margin: 0;
      }

      & a {
        transition: 300ms color ease;

        &:hover,
        &.current {
          color: #cccccc;
        }
      }

      &.search-icon {
        & svg {
          & circle,
          & path {
            stroke: #000;
            transition: 300ms stroke ease;
          }
        }

        &:hover {
          & svg {
            & circle,
            & path {
              stroke: #cccccc;
            }
          }
        }
      }
    }

    @media (max-width: 1320px) {
      top: 35px;

      & li {
        margin: 0 30px 0 0;
      }
    }

    @media (max-width: 1100px) {
      top: 32px;
    }
  }

  & .exhibition-title {
    & h1 {
      font-family: "Sabon LT W04 Italic", "Times", serif;
    }

    & h1,
    & h2 {
      @media (max-width: 1320px) {
        font-size: 28px;
        line-height: 35px;
      }

      @media (max-width: 1100px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  & .title-container {
    position: relative;
    width: calc(100% - 865px - 30px - 30px);

    @media (max-width: 1320px) {
      width: calc(100% - 650px - 30px - 30px);
    }

    @media (max-width: 1100px) {
      width: calc(100% - 570px - 30px - 30px);
    }

    & .logo,
    & .exhibition-title {
      position: absolute;
      top: 0;
      transition: 400ms transform ease-in-out;
    }

    & .exhibition-title {
      top: 4px;

      @media (max-width: 1320px) {
        top: -1px;
      }

      @media (max-width: 1100px) {
      }
    }
  }

  &.logo-visible {
    & .logo {
      transform: translateY(0);
    }

    & .exhibition-title {
      transform: translateY(-200%);
    }
  }

  &.logo-invisible {
    & .logo {
      transform: translateY(-200%);
    }

    & .exhibition-title {
      transform: translateY(0);
    }
  }

  &.page-type-home {
    background: none;
  }

  &.search-is-open {
    & ol {
      & .page-link {
        display: none;
      }
    }
  }
`;

export const DesktopMenu = ({
  links,
  location,
  pageTitle,
  pageType,
  isHeaderVisible,
  isSearchVisible,
  setIsSearchVisible,
}) => {
  return (
    <Navigation
      className={`display-flex space-between ${
        isHeaderVisible ? `logo-visible` : `logo-invisible`
      } page-type-${pageType} ${
        isSearchVisible ? `search-is-open` : `search-is-closed`
      }`}
    >
      {pageType !== `exhibition` && (
        <Link to={`/`} className="logo uppercase">
          <Logo />
        </Link>
      )}

      {pageType === `exhibition` && (
        <div className="title-container">
          <Link to={`/`} className="logo uppercase">
            <Logo />
          </Link>

          <div className="exhibition-title" id="header-exhibition-title">
            <PrismicRichText field={pageTitle.title} />
            <PrismicRichText field={pageTitle.subtitle} />
          </div>
        </div>
      )}

      <ol className="display-flex">
        {links}

        <li className={`search-icon ${isSearchVisible ? `is-open` : ``}`}>
          <SearchIndex
            isSearchVisible={isSearchVisible}
            setIsSearchVisible={setIsSearchVisible}
            location={location}
            isDesktop={true}
          />
        </li>
      </ol>
    </Navigation>
  );
};
