import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

// Context
import { PageSetup } from "../context/page-context";

// Components
import { Header } from "./header";
import { Footer } from "./footer";

const Main = styled.main`
  flex: 1;
  min-height: ${(props) => props.height}px;
`;

export const App = ({ location, children, height }) => {
  const [pageSetup, setPageSetup] = useState({
    pageTitle: ``,
    pageType: ``,
    isHeaderVisible: true,
  });

  const duration = 0.35;

  const variants = {
    initial: {
      opacity: 0,
      y: 0,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: duration,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: { duration: duration },
    },
  };

  return (
    <PageSetup.Provider value={[pageSetup, setPageSetup]}>
      <>
        <Header
          location={location}
          pageTitle={pageSetup.pageTitle}
          pageType={pageSetup.pageType}
          isHeaderVisible={pageSetup.isHeaderVisible}
          pageSetup={pageSetup}
        />

        <motion.div
          key={location}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <Main height={height}>{children}</Main>
          {pageSetup.pageType !== `home` && <Footer location={location} />}
        </motion.div>
      </>
    </PageSetup.Provider>
  );
};
