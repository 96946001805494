exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-editions-js": () => import("./../../../src/pages/editions.js" /* webpackChunkName: "component---src-pages-editions-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-fairs-js": () => import("./../../../src/pages/fairs.js" /* webpackChunkName: "component---src-pages-fairs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-artist-url-js": () => import("./../../../src/pages/{PrismicArtist.url}.js" /* webpackChunkName: "component---src-pages-prismic-artist-url-js" */),
  "component---src-pages-prismic-edition-url-js": () => import("./../../../src/pages/{PrismicEdition.url}.js" /* webpackChunkName: "component---src-pages-prismic-edition-url-js" */),
  "component---src-pages-prismic-exhibition-url-js": () => import("./../../../src/pages/{PrismicExhibition.url}.js" /* webpackChunkName: "component---src-pages-prismic-exhibition-url-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/{PrismicPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

