import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const useMenuData = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicSettings {
        data {
          menu {
            menu1 {
              document {
                ... on PrismicNews {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicArtists {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicExhibitions {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicFairs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicInfo {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicEditions {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicEdition {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data;
};
