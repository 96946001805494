import React from "react";

export const CloseSlideshowIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L31 31" stroke="black" />
    <path d="M1 31L31 1" stroke="black" />
  </svg>
);
