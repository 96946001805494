import { useState, useEffect } from "react";

function useScrollHeaderToggle(threshold) {
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY && currentScrollY > threshold) {
        // Scrolling down, hide the header
        setIsVisible(false);
      } else {
        // Scrolling up or not past the threshold, show the header
        setIsVisible(true);
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY, threshold]);

  return isVisible;
}

export default useScrollHeaderToggle;
