import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}


html,
body {
  overscroll-behavior: none;
}


body {
  font-family: "Sabon LT W04 Roman", "Times", serif;
  font-weight: normal;
  font-style: normal;

  font-size: 24px;
  line-height: 30px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  word-break: break-word;

  color: #000;
  margin: 0;

  @media (max-width: 1300px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 24px;
  }
}

em {
  font-family: "Sabon LT W04 Italic", "Times", serif;
}

.body-large,
.body-large p,
.body-large h1,
.body-large h2,
.body-large h3 {
  font-size: 32px;
  line-height: 40px;

  @media (max-width: 1320px) {
    font-size: 28px;
    line-height: 35px;
  }

  @media (max-width: 1100px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 700px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 26px;
  }
}

.body-medium,
.body-medium p,
.body-medium h1,
.body-medium h2,
.body-medium h3 {
  font-size: 24px;
  line-height: 30px;

   @media (max-width: 1320px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.caption,
.caption p {
  font-size: 18px;
  line-height: 22px;

    @media (max-width: 1320px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 700px) {
      font-size: 16px;
      line-height: 20px;
    }
}

.uppercase {
  text-transform: uppercase;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Sabon LT W04 Roman", "Times", serif;
  font-weight: normal;

  margin: 0;

  font-size: 32px;
  line-height: 40px;

  @media (max-width: 1320px) {
    font-size: 28px;
    line-height: 35px;
  }

  @media (max-width: 1100px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 700px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 26px;
  }
}

b,
strong{
  font-weight: normal !important;
  color: #cccccc;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Masonry
  --------------------------------------------------------------*/

.my-masonry-grid {
  display: flex;
  margin-left: 0; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 0; /* gutter size */
  background-clip: padding-box;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    color: #000;
    cursor: pointer;

    border:0;
    padding:0;
    margin:0;

    display: block;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}


/*--------------------------------------------------------------
  ## Layout
  --------------------------------------------------------------*/

.display-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;

  @media (max-width: 860px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }
}


/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-lightbox,
.ReactModalPortal-splash,
.ReactModalPortal-artworks-lightbox {
  position: fixed !important;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #fff !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-splash .ReactModal__Overlay,
.ReactModalPortal-lightbox .ReactModal__Overlay {
  background-color: transparent !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

`;

export default GlobalStyle;
