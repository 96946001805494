import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Icons
import { Logo } from "../icons/logo";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 15px 30px 30px 30px;

  background-color: #fff;
  position: relative;
  z-index: 10;

  & svg {
    width: 140px;
    height: auto;
  }

  & ol {
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;

    width: 100%;
  }

  & ol,
  & li {
    list-style: none;
  }

  & li {
    & p {
      margin: 0;
    }

    & button {
      margin: 14px 0 0 0;
    }
  }

  & .mailing-list-container {
    margin: 8px 0 0 0;
  }

  @media (hover: hover) and (pointer: fine) {
    & a {
      transition: 300ms color ease;

      &:hover {
        color: #cccccc;
      }
    }
  }

  @media (max-width: 1000px) {
    & ol {
      grid-template-columns: 1fr 1fr;
    }

    & li {
      &:nth-of-type(4n + 1) {
        order: 1;
        margin: 0 0 30px 0;
      }
      &:nth-of-type(4n + 2) {
        order: 3;
      }
      &:nth-of-type(4n + 3) {
        order: 4;
      }
      &:nth-of-type(4n + 4) {
        order: 2;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 15px;
  }

  @media (max-width: 700px) {
    & ol {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-template-columns: unset;
      grid-column-gap: 0;
    }

    & li {
      flex: 100%;
      width: 100%;

      margin: 0 0 1em 0;

      &:nth-of-type(4n + 1) {
        order: 1;
        margin: 0 0 1em 0;
      }
      &:nth-of-type(4n + 2) {
        order: 3;
      }
      &:nth-of-type(4n + 3) {
        order: 4;
        margin: 0;
      }
      &:nth-of-type(4n + 4) {
        order: 2;
      }
    }
  }
`;

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicSettings {
        data {
          address {
            richText
          }
          address_two {
            richText
          }
          text {
            richText
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <ol>
        <li>
          <Logo />

          <div className="mailing-list-container">
            <a
              href="https://app.artlogic.net/unionpacific/signup"
              className="caption"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join our mailing list
            </a>
          </div>
        </li>

        <li className="caption">
          <PrismicRichText field={data.prismicSettings.data.address.richText} />
        </li>

        <li className="caption">
          <PrismicRichText
            field={data.prismicSettings.data.address_two.richText}
          />
        </li>

        <li className="caption">
          <PrismicRichText field={data.prismicSettings.data.text.richText} />
          <p>
            <a
              href="https://kieranstartup.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Site Credits
            </a>
          </p>
        </li>
      </ol>
    </FooterWrapper>
  );
};
