import React from "react";

export const SearchIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.0371 17.0371L23.0001 23.0001" stroke={fill} />
    <circle cx="10.6479" cy="9.7963" r="9.2963" stroke={fill} />
  </svg>
);
